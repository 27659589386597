import { Stack, Typography } from '@mui/material';
import { Debug as IDebug } from '../../contexts/MediaContext';

interface Props {
    debug?: IDebug;
}

export const Debug = ({ debug }: Props) => {
    if (!debug) {
        return null;
    }

    return (
        <Stack
            direction="column"
            sx={{
                p: 2,
            }}
        >
            <img
                src={debug?.queryImage}
                alt="random"
                style={{
                    maxWidth: '100%',
                    maxHeight: 250,
                    objectFit: 'contain',
                }}
            />
            <Typography>Query Resolution: {JSON.stringify(debug.queryResolution)}</Typography>
            <Typography>Init Time: {debug.initTime?.toFixed(3)}</Typography>
            <Typography>Queueing: {((debug.dequeueTime ?? 0) - (debug.enqueueTime ?? 0)).toFixed(3)}</Typography>
            <Typography>
                Processing: {((debug.processEndTime ?? 0) - (debug.processStartTime ?? 0)).toFixed(3)}
            </Typography>
            <Typography>
                Time to Debug Screen: {((debug.processEndTime ?? 0) - (debug.initTime ?? 0)).toFixed(3)}
            </Typography>
            <Typography>Search: {((debug.queryEndTime ?? 0) - (debug.queryStartTime ?? 0)).toFixed(3)}</Typography>
        </Stack>
    );
};
