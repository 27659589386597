import { Stack, Typography } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { Color } from 'src/Color';
import { Type, Parameter } from 'src/hooks/analytics/useAnalytics';
import useKeenAnalytics from 'src/hooks/analytics/useKeenAnalytics';
import ResponsiveLineChart from './ResponsiveLineChart';
import useIrdbAnalytics from 'src/hooks/analytics/useIrdbAnalytics';
import { Event, Interval, Metric, Source, TimeframeAndInterval } from 'src/types/Analytics';

interface Props {
    source: Source;
    campaignID: number;
    metric: Metric;
    timeframeAndInterval?: TimeframeAndInterval;

    // Keen
    // timeframe: Timeframe;
    // interval: Interval;

    // IRDB
    // days: number;
    // unit: string; // wanted to call this interval but it conflicts with the Keen interval
    start?: string;
    end?: string;
}

export default function ViewsHistoryChart({ source, campaignID, metric, timeframeAndInterval, start, end }: Props) {
    interface CampaignView {
        x: string | number;
        y: number;
    }

    const [campaignViews, setCampaignViews] = useState<CampaignView[]>([]);

    const { query } = useKeenAnalytics();
    const fetchKeenAnalytics = useCallback(() => {
        console.log('[ANALYTICS] fetchKeenAnalytics', campaignID, timeframeAndInterval, start, end, metric);

        return new Promise<CampaignView[]>((resolve, reject) => {
            query(
                // Type.CountUnique,
                Type.Count,
                {
                    event_collection: Event.ImageViewed,
                    timeframe: timeframeAndInterval?.timeframe,
                    interval: timeframeAndInterval?.interval,
                    filters: [
                        {
                            property_name: Parameter.CampaignID,
                            operator: 'eq',
                            property_value: campaignID,
                        },
                    ],
                    // group_by: ['campaign_id']
                },
            )
                .then(results => {
                    console.log('results', results);
                    resolve(
                        results.map((result, index: number) => {
                            return {
                                // id: String(index),
                                // data: result.value,
                                // id: click..category,
                                // label: result.category,
                                // value: result.result,
                                // color: randomColor(),
                                // ...click,
                                // @ts-ignore
                                x: result.timeframe.start ?? index,
                                y: result.value as number,
                            };
                        }),
                        // .sort((a: Click, b: Click) => b.result - a.result)
                    );
                })
                .catch((error: any) => {
                    console.error('error', error);
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, timeframeAndInterval, start, end, metric]);

    const { fetch } = useIrdbAnalytics();
    const fetchIrdbAnalytics = useCallback(() => {
        console.log('[ANALYTICS] fetchIrdbAnalytics', campaignID, timeframeAndInterval, start, end, metric);

        return new Promise<CampaignView[]>((resolve, reject) => {
            fetch<CampaignView[]>({
                campaignID,
                metric,
                timeframe: timeframeAndInterval?.days,
                interval: timeframeAndInterval?.unit ?? 'days',
                start,
                end,
                aggregated: true,
            })
                .then(results => {
                    console.log('results', results);
                    resolve(
                        results.map((result, index: number) => {
                            return {
                                // id: String(index),
                                // data: result.value,
                                // id: click..category,
                                // label: result.category,
                                // value: result.result,
                                // color: randomColor(),
                                // ...click,
                                // @ts-ignore
                                x: result.timeframe.start ?? index,
                                y: result.value as number,
                            };
                        }),
                        // .sort((a: Click, b: Click) => b.result - a.result)
                    );
                })
                .catch((error: any) => {
                    console.error('error', error);
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, timeframeAndInterval, start, end, metric]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        const fetchAnalytics = source === Source.Keen ? fetchKeenAnalytics : fetchIrdbAnalytics;
        fetchAnalytics()
            .then(campaignViews => {
                setCampaignViews(campaignViews);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [source, campaignID, fetchIrdbAnalytics, fetchKeenAnalytics]);

    return (
        <Stack direction="column">
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                }}
            >
                View History
            </Typography>
            <ResponsiveLineChart
                data={[
                    {
                        id: campaignID,
                        color: Color.Purple,
                        data: campaignViews,
                    },
                ]}
                interval={timeframeAndInterval?.interval ?? Interval.Daily}
            />
        </Stack>
    );
}
