import {
    Add,
    CancellablePromise,
    ImageOperation,
    Init,
    Query,
    RegistrationStatus,
    replaceImageOperation,
    TMedia,
    Upload,
} from 'src/contexts/MediaContext';
import {
    BulkUploaderBaseState,
    BulkUploaderComputedState,
    BulkUploaderStore,
    getBulkUploadErrorMessage,
} from '../store';
import { arePropertiesEqual } from '../../../util/objects';
import { getAllImageOperationsCount } from '../../../contexts/specialized/BulkUploaderContext';
import { ComputedSelector, StateCreatorWithDependencies } from '../../../util/zustand';
import { TOperationsTimeEstimation } from '../../../util/classes/OperationsTimeEstimation';
import { TMeta, validateMeta } from '../../../contexts/MetaContext';
import { TPromiseQueue } from '../../../util/classes/PromiseQueue';
import { findRejected, flattenRejectReasons } from '../../../util/promise';
import { Type } from '../../../contexts/Operation';
import { TFeedback } from '../../../contexts/FeedbackContext';
import { SetMetaErrors } from '../../../hooks/metaErrors/useMetaErrors';
import { MetaErrors } from 'src/types/MetaValidation';

export type IRCodes = {
    [key in
        | 'pendingIrcodes'
        | 'availableIrcodes'
        | 'addingIrcodes'
        | 'addedIrcodes'
        | 'unavailableIrcodes']: ImageOperation<any>[];
};

export interface ImageOperationsSlice extends IRCodes {
    files: File[];
    imageOperationsCount: number;

    setFiles(files: File[]): void;
    replaceImageOperation(imageOperation: ImageOperation<any>): void;
    removeImageOperation(imageOperation: ImageOperation<any>): void;
    clearImageOperations(): void;
    processFiles(): Promise<void>;
    registerSelectedImages(onStart?: () => void, onAdd?: (imageId: string) => Promise<void>): Promise<void>;
    handleCancel(reset?: boolean): void;
}

export interface ImageOperationsComputedState {
    hasImageOperations: boolean;
    hasPending: boolean;
    hasAvailable: boolean;
    hasAdding: boolean;
    hasAdded: boolean;
    hasUnavailable: boolean;
    /** True if there are available ircodes currently uploading */
    hasUploading: boolean;
}

const getHas = (
    state: BulkUploaderBaseState & Partial<BulkUploaderComputedState>,
    prevState: BulkUploaderBaseState & Partial<BulkUploaderComputedState>,
    category: keyof IRCodes,
    computedKey: keyof ImageOperationsComputedState,
) => (state[category].length === prevState[category].length ? !!prevState[computedKey] : state[category].length > 0);

export const imageOperationsComputedSelectors: {
    [key in keyof ImageOperationsComputedState]: ComputedSelector<
        BulkUploaderBaseState & Partial<BulkUploaderComputedState>,
        ImageOperationsComputedState[key]
    >;
} = {
    hasAdded: (state, prevState) => getHas(state, prevState, 'addedIrcodes', 'hasAdded'),
    hasAdding: (state, prevState) => getHas(state, prevState, 'addingIrcodes', 'hasAdding'),
    hasAvailable: (state, prevState) => getHas(state, prevState, 'availableIrcodes', 'hasAvailable'),
    hasPending: (state, prevState) => getHas(state, prevState, 'pendingIrcodes', 'hasPending'),
    hasUnavailable: (state, prevState) => getHas(state, prevState, 'unavailableIrcodes', 'hasUnavailable'),

    hasImageOperations: (state, prevState) =>
        state.imageOperationsCount === prevState.imageOperationsCount ?
            !!prevState.hasImageOperations
        :   state.imageOperationsCount > 0,
    hasUploading: (state, prevState) =>
        state.availableIrcodes === prevState.availableIrcodes ?
            !!prevState.hasUploading
        :   state.availableIrcodes.some(i => i.operation.type === Type.Upload),
};

const statusMap = new Map<RegistrationStatus, keyof IRCodes>([
    [RegistrationStatus.Pending, 'pendingIrcodes'],
    [RegistrationStatus.Available, 'availableIrcodes'],
    [RegistrationStatus.Unavailable, 'unavailableIrcodes'],
    [RegistrationStatus.Adding, 'addingIrcodes'],
    [RegistrationStatus.Added, 'addedIrcodes'],
]);

const statuses = Array.from(statusMap.keys());

const findIrcodesCategoryAndIndex = (state: BulkUploaderStore, imageOperation: ImageOperation<any>) => {
    const { id } = imageOperation;
    for (let i = 0; i < statuses.length; i++) {
        const status = statuses[i];
        const category = statusMap.get(status)!;
        const index = state[category].findIndex(i => i.id === id);
        if (index !== -1) {
            return [category, index] as const;
        }
    }
    return null;
};

/** Finds the array containing this image operation using id comparison and removes it from the array.
 * @param {ImageOperation<any>} imageOperation - The image operation to remove.
 * @param {BulkUploaderStore} state - The current state.
 * @param {boolean} [disableSameCategoryCheck] - If true, the function will remove the image operation even if it has the same category.
 * */
const remove = (
    imageOperation: ImageOperation<any>,
    state: BulkUploaderStore,
    disableSameCategoryCheck?: boolean,
): Partial<IRCodes> | null => {
    const prevImageOperationInfo = findIrcodesCategoryAndIndex(state, imageOperation);
    if (prevImageOperationInfo) {
        const [category, index] = prevImageOperationInfo;
        if (disableSameCategoryCheck || category !== statusMap.get(imageOperation.status)) {
            const newCategoryValue = [...state[category]];
            newCategoryValue.splice(index, 1);
            return { [category]: newCategoryValue };
        }
    }
    return null;
};

/** Finds the array containing this image operation using id comparison and removes it from the array, then adds the new image operation to its category.
 * If the image operation's category is the same, it is only replaced.
 * @param {ImageOperation<any>} imageOperation - The image operation to replace.
 * @param {BulkUploaderStore} state - The current state.
 * */
const replace = (imageOperation: ImageOperation<any>, state: BulkUploaderStore): Partial<IRCodes> | null => {
    let resultState: Partial<IRCodes> | null = null;
    const removeResult = remove(imageOperation, state);
    if (removeResult) {
        resultState = removeResult;
    }
    if (statusMap.has(imageOperation.status)) {
        const newCategory = statusMap.get(imageOperation.status)!;
        const newCategoryValue = replaceImageOperation(imageOperation, state[newCategory]);
        resultState ??= {};
        resultState[newCategory] = newCategoryValue;
    }
    return resultState;
};

const handleValidateAvailable = (
    availableIrcodes: ImageOperation<any>[],
    onError: (id: string, errors: MetaErrors) => void,
) => {
    const validatedAvailable: ImageOperation<any>[] = [];
    let hasErrors = false;
    for (let i = 0; i < availableIrcodes.length; i++) {
        const imageOperation = availableIrcodes[i];
        const { bulkOperation } = imageOperation;
        if (!bulkOperation) {
            continue;
        }
        const meta = bulkOperation.meta;
        if (!meta) {
            continue;
        }
        const { results, errors } = validateMeta(meta);
        if (errors) {
            onError(imageOperation.id, errors);
            hasErrors = true;
        }
        // We want to continue validating the rest of the available images to get all the errors,
        // but we don't need to push the results
        else if (!hasErrors) {
            validatedAvailable.push({
                ...imageOperation,
                bulkOperation: {
                    ...bulkOperation,
                    meta: results,
                },
            });
        }
    }
    if (hasErrors) {
        return;
    }
    return validatedAvailable;
};

const handleGetImageOperationsCount = (state: BulkUploaderStore, prevState: BulkUploaderStore): number | undefined => {
    if (arePropertiesEqual(state, prevState, 'pendingIrcodes', 'availableIrcodes', 'addingIrcodes')) return;
    const newCount = getAllImageOperationsCount(state);
    if (newCount === state.imageOperationsCount) return;
    return newCount;
};

export interface ImageOperationsSliceDependencies {
    Media: TMedia;
    Meta: TMeta;
    Feedback: TFeedback;
    promiseQueue: TPromiseQueue<void, PromiseRejectedResult[]>;
    operationsTimeEstimation: TOperationsTimeEstimation;
    setMetaErrors: SetMetaErrors;
}

const createImageOperationsSlice: StateCreatorWithDependencies<
    BulkUploaderStore,
    [],
    [],
    ImageOperationsSlice,
    ImageOperationsSliceDependencies
> = (set, get, store, { operationsTimeEstimation, promiseQueue, Meta, Media, Feedback, setMetaErrors }) => {
    const cancelHandlers: { [key: string]: () => void } = {};
    const updateCancelHandlers = (imageOperation: CancellablePromise<unknown>) => {
        cancelHandlers[imageOperation.id] = imageOperation.cancel;
    };
    const removeCancelHandler = (imageOperation: ImageOperation<unknown>) => {
        delete cancelHandlers[imageOperation.id];
    };
    const slice: ImageOperationsSlice = {
        files: [],
        imageOperationsCount: 0,
        pendingIrcodes: [],
        availableIrcodes: [],
        unavailableIrcodes: [],
        addingIrcodes: [],
        addedIrcodes: [],
        setFiles(files: File[]) {
            set({ files });
        },
        replaceImageOperation(imageOperation: ImageOperation<any>) {
            const resultState = replace(imageOperation, get());
            if (resultState) {
                set(resultState);
            }
        },
        removeImageOperation(imageOperation: ImageOperation<any>) {
            const resultState = remove(imageOperation, get(), true);
            if (resultState) {
                set(resultState);
            }
        },
        clearImageOperations() {
            set({
                pendingIrcodes: [],
                availableIrcodes: [],
                addingIrcodes: [],
                addedIrcodes: [],
                unavailableIrcodes: [],
            });
        },
        handleCancel(reset?: boolean) {
            const { setIsCanceling, clearImageOperations } = get();
            setIsCanceling(true);
            Object.values(cancelHandlers).forEach(cancel => {
                cancel();
            });
            if (reset) {
                clearImageOperations();
            }
        },
        async processFiles() {
            const { init, prep, foveate, query } = Media;
            const { addToQueue } = promiseQueue;
            const {
                files,
                incrementProcessingCount,
                incrementProcessedCount,
                replaceImageOperation,
                removeImageOperation,
            } = get();
            const filesAmount = files.length;
            incrementProcessingCount(filesAmount);
            let estimationStarted = false;

            const handleInitOperation = async (imageOperation: ImageOperation<Init>) => {
                try {
                    // We want this to run only once, as close as possible to the start of the first operation
                    if (!estimationStarted) {
                        const { startEstimation, addOperations, isEstimatingTime } = operationsTimeEstimation;
                        // Check if this is not the first call to processFiles in queue
                        if (isEstimatingTime) {
                            addOperations(filesAmount);
                        } else {
                            startEstimation(filesAmount);
                        }
                        estimationStarted = true;
                    }
                    const p = prep(imageOperation, true);
                    updateCancelHandlers(p);
                    const prepped = await p.promise;
                    replaceImageOperation(prepped);

                    const f = foveate(prepped);
                    updateCancelHandlers(f);
                    const foveated = await f.promise;
                    replaceImageOperation(foveated);

                    const q = query(foveated, (progress: ImageOperation<Query>) => {
                        replaceImageOperation(progress);
                    });
                    updateCancelHandlers(q);
                    const queried = await q.promise;
                    replaceImageOperation(queried);
                    removeCancelHandler(queried);

                    console.log('One finished');
                } catch (error) {
                    console.log('Error', error);
                    removeImageOperation(imageOperation);
                    removeCancelHandler(imageOperation);
                    throw error;
                }
            };

            const handleFile = async (file: File) => {
                const { advanceEstimation } = operationsTimeEstimation;
                const i = init(file, { width: 0, height: 0 }, performance.now(), (progress: ImageOperation<Init>) => {
                    replaceImageOperation(progress);
                });
                updateCancelHandlers(i);
                const initialized = await i.promise;
                const results = await Promise.allSettled(initialized.map(handleInitOperation));
                incrementProcessedCount();
                advanceEstimation();
                const errors = findRejected(results);
                if (errors.length) {
                    throw errors;
                }
                return results;
            };

            addToQueue(async () => {
                const results = await Promise.allSettled(files.map(handleFile));
                const errors = flattenRejectReasons(findRejected(results));
                if (errors.length) {
                    throw errors;
                }
            });

            set({ files: [] });
        },
        async registerSelectedImages(onStart?: () => void, onAdd?: (imageId: string) => Promise<void>) {
            const { startEstimation, advanceEstimation, stopEstimation } = operationsTimeEstimation;
            const { upload, add } = Media;
            const { save } = Meta;
            const { notify } = Feedback;
            const { availableIrcodes, replaceImageOperation, removeImageOperation, incrementProcessedCount } = get();
            try {
                const filteredOperations = handleValidateAvailable(
                    availableIrcodes.filter(i => i.bulkOperation?.selected === true),
                    setMetaErrors,
                );
                if (!filteredOperations) {
                    await notify('Information missing or invalid', 'Please fill in all required fields');
                    return;
                }
                onStart?.();
                const len = filteredOperations.length;
                set({ processingCount: len });
                startEstimation(len);
                const added = await Promise.allSettled(
                    filteredOperations.map(async i => {
                        try {
                            const u = upload(i, (progress: ImageOperation<Upload>) => {
                                replaceImageOperation(progress);
                            });
                            updateCancelHandlers(u);
                            const uploaded = await u.promise;
                            replaceImageOperation(uploaded);

                            const a = add(uploaded, uploaded.bulkOperation?.status, (added: ImageOperation<Add>) => {
                                replaceImageOperation(added);
                            });
                            updateCancelHandlers(a);
                            const added = await a.promise;
                            replaceImageOperation(uploaded);

                            removeCancelHandler(added);
                            // The operation cannot be cancelled from here on

                            await save(
                                added.operation.Results!.Image.imageID,
                                added.bulkOperation?.meta ?? [],
                                status => {
                                    console.log('status', status);
                                    const newAdded = structuredClone(added);
                                    newAdded.operation.status = status;
                                    replaceImageOperation(newAdded);
                                },
                            );

                            // TODO: I don't like this
                            const newAdded = structuredClone(added);
                            newAdded.operation.type = Type.Completed;
                            replaceImageOperation(newAdded);

                            await onAdd?.(newAdded.operation.Results!.Image.imageID);

                            return added;
                        } catch (error) {
                            removeImageOperation(i);
                            removeCancelHandler(i);
                            throw error;
                        } finally {
                            incrementProcessedCount();
                            advanceEstimation();
                        }
                    }),
                );

                // console.log('added', added);
                // console.log('All Adds complete');
                const errors = findRejected(added);
                if (errors.length) {
                    const errorMessage = getBulkUploadErrorMessage(errors);
                    if (errorMessage) {
                        await notify('Upload Error', errorMessage);
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                set({ processingCount: 0, processedCount: 1, isCanceling: false, isProcessing: false });
                stopEstimation();
            }
        },
    };
    store.subscribe((state, prevState) => {
        if (
            arePropertiesEqual(
                state,
                prevState,
                'pendingIrcodes',
                'availableIrcodes',
                'addingIrcodes',
                'addedIrcodes',
                'unavailableIrcodes',
            )
        )
            return;
        const imageOperationsCount = handleGetImageOperationsCount(state, prevState);
        if (imageOperationsCount !== undefined) {
            set({ imageOperationsCount });
        }
    });
    return slice;
};

export default createImageOperationsSlice;
