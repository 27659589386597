import { useProfileInfo } from '../../../stores/useProfileState';
import { MIN_PASSWORD_LENGTH } from '../../../hooks/usePasswordValidator';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';

const transition = 'color 0.2s';
function PasswordValidationListItem({ content, isChecked }: { content: string; isChecked: boolean }) {
    return (
        <ListItem disableGutters disablePadding>
            <ListItemIcon sx={{ minWidth: '40px' }}>
                <i
                    className="fa-solid fa-circle-check"
                    style={{ color: isChecked ? '#67cd67' : '#525157', transition }}
                />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: { transition } }} primary={content} />
        </ListItem>
    );
}

const PasswordValidationDisplay = () => {
    const useSelectors = useProfileInfo().use;
    const isPasswordNotEmpty = !!useSelectors.newPassword();
    const newPasswordValidState = useSelectors.passwordValidState();
    return (
        <Collapse timeout={600} in={isPasswordNotEmpty} style={{ marginTop: 0 }}>
            <List disablePadding sx={{ pt: 2 }}>
                <PasswordValidationListItem
                    content={`Minimum ${MIN_PASSWORD_LENGTH} characters`}
                    isChecked={newPasswordValidState.length}
                />
                <PasswordValidationListItem
                    content="At least 1 lowercased letter"
                    isChecked={newPasswordValidState.lowerCaseLetter}
                />
                <PasswordValidationListItem
                    content="At least 1 uppercased letter"
                    isChecked={newPasswordValidState.upperCaseLetter}
                />
                <PasswordValidationListItem content="At least 1 number" isChecked={newPasswordValidState.number} />
                <PasswordValidationListItem
                    content="At least 1 special character"
                    isChecked={newPasswordValidState.specialCharacter}
                />
            </List>
        </Collapse>
    );
};

export default PasswordValidationDisplay;
