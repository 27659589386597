import axios, { AxiosResponse } from 'axios';
import { CancellablePromise } from 'src/contexts/MediaContext';
import { AbortablePromise } from 'src/util/AbortablePromise/AbortablePromise';
import { AbortError } from 'src/util/AbortablePromise/AbortError';
import { CAMERA_CAPTURE_FILENAME, convertToJpeg } from 'src/util/image';

interface AzureSearchResponse {
    ircodeID?: string;
}

export interface TOcr {
    findBadge: ({ file }: { file: File }) => CancellablePromise<string | undefined>;
}

const useOcr = (): TOcr => {
    const searchApi = axios.create({
        baseURL: process.env.REACT_APP_OCR_SEARCH_API,
        timeout: 5000,
        headers: {
            'Content-Type': 'application/octet-stream',
            'x-functions-key': process.env.REACT_APP_OCR_SEARCH_KEY,
        },
    });

    const findBadge = ({ file }: { file: File }): CancellablePromise<string | undefined> => {
        const promise = new AbortablePromise<string | undefined>(async (resolve, reject, abortController) => {
            if (abortController.signal.aborted) {
                reject(new AbortError());
                return;
            }

            try {
                const reader = new FileReader();
                reader.readAsArrayBuffer((await convertToJpeg(file, { quality: 0.5 })).file);

                const event = await new Promise<ProgressEvent<FileReader>>((resolve, reject) => {
                    reader.onloadend = resolve;
                    reader.onerror = reject;
                });

                if (abortController.signal.aborted) {
                    reject(new AbortError());
                    return;
                }

                if (!event?.target) {
                    resolve(undefined);
                }

                const xVisualCorrections = false && file.name === CAMERA_CAPTURE_FILENAME ? undefined : 'entire-image';
                const response: AxiosResponse<AzureSearchResponse> = await searchApi.post(
                    '/find-badge',
                    event.target!.result,
                    {
                        headers: {
                            'x-visual-corrections': xVisualCorrections,
                        },
                        signal: abortController?.signal,
                    },
                );
                // console.log('response', response);

                resolve(response.data.ircodeID);
            } catch (error) {
                // console.error(error);
                // We don't want to reject, just resolve with undefined
                resolve(undefined);
            }
        });

        return {
            id: crypto.randomUUID() as string,
            promise,
            cancel: () => promise.abort(),
        };
    };

    return { findBadge };
};

export default useOcr;
