// These can be a lot of different values, add as needed
// ThisNUnits = "this_n_units",
// PreviousNUnits = "previous_n_units",
// ThisUnit = "this_unit",
// PreviousUnit = "previous_unit",
// Today = "today",
// Yesterday = "yesterday",
export enum Timeframe {
    This1Days = 'this_1_days',
    This3Days = 'this_3_days',
    This5Days = 'this_5_days',
    This7Days = 'this_7_days',
    This30Days = 'this_30_days',
    This90Days = 'this_90_days',
    This180Days = 'this_180_days',
    ThisYear = 'this_year',
    All = 'all',
}

// EveryNUnits = every_N_units
export enum Interval {
    Minutely = 'minutely',
    Hourly = 'hourly',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
}

export enum Source {
    Keen = 'keen',
    IRDB = 'irdb',
}

export interface TimeframeAndInterval {
    label: string;
    timeframe: Timeframe;
    interval: Interval;
    days: number;
    unit: 'hours' | 'days' | 'weeks' | 'months';
}

export const timeframeAndIntervals = [
    {
        label: '1D',
        timeframe: Timeframe.This1Days,
        interval: Interval.Hourly,
        days: 1,
        unit: 'hours' as const,
    },
    {
        label: '3D',
        timeframe: Timeframe.This3Days,
        interval: Interval.Daily,
        days: 3,
        unit: 'days' as const,
    },
    {
        label: '5D',
        timeframe: Timeframe.This5Days,
        interval: Interval.Daily,
        days: 5,
        unit: 'days' as const,
    },
    {
        label: '1W',
        timeframe: Timeframe.This7Days,
        interval: Interval.Daily,
        days: 7,
        unit: 'days' as const,
    },
    {
        label: '1M',
        timeframe: Timeframe.This30Days,
        interval: Interval.Daily,
        days: 30,
        unit: 'days' as const,
    },
    {
        label: '3M',
        timeframe: Timeframe.This90Days,
        interval: Interval.Weekly,
        days: 90,
        unit: 'weeks' as const,
    },
    {
        label: '6M',
        timeframe: Timeframe.This180Days,
        interval: Interval.Weekly,
        days: 180,
        unit: 'weeks' as const,
    },
    {
        label: 'YTD',
        timeframe: Timeframe.ThisYear,
        interval: Interval.Monthly,
        // TODO: This should be current DoY
        days: 365,
        unit: 'months' as const,
    },
    // {
    //     label: 'ALL',
    //     value: Timeframe.All,
    // },
];

export enum Metric {
    Views = 'views',
    Scans = 'scans',
}

export enum Event {
    AppOpened = 'App Opened',
    ImageScanned = 'Image Scanned',
    ImageSaved = 'Image Saved',
    ImageShared = 'Image Shared',
    UserAccountCreated = 'User Account Created',
    ImageViewed = 'Image Viewed',
    ImageLinkOpened = 'Image Link Opened',
    UserProfileViewed = 'User Profile Viewed',
}
