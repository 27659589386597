// TODO: I think this whole hook can be folded into useLiveVisualSearch

import { CancellablePromise, debug, ImageOperation, Query, RegistrationStatus } from 'src/contexts/MediaContext';
import axios, { CanceledError } from 'axios';
import { Semaphore } from 'async-mutex';
import { AbortablePromise } from 'src/util/AbortablePromise/AbortablePromise';
import { Type } from 'src/contexts/Operation';
import { getProgressHandler } from 'src/contexts/ProgressHandler';
import { calculateFoveationRegionsWithImageSize, convertToJpeg } from 'src/util/image';
import { AbortError } from 'src/util/AbortablePromise/AbortError';
// import curlirize from 'axios-curlirize';
import useFetchImage from './be/useFetchImage';
import { useRef } from 'react';
// import { v4 as uuidv4 } from 'uuid';

function invUUIDv7(): string {
    // Get the current time in milliseconds as a hex string
    const timeInMilliseconds = Math.floor(Date.now());
    let timeHex = timeInMilliseconds.toString(16).padStart(12, '0');

    // Invert each character in the hexadecimal string
    timeHex = Array.from(timeHex)
        .map(char => {
            if (char === '-') {
                return char;
            } else {
                const value = 15 - parseInt(char, 16);
                return value.toString(16);
            }
        })
        .join('');

    // Generate random bytes and convert them to a hex string
    const randomBytes = crypto.getRandomValues(new Uint8Array(10));
    const randHex = Array.from(randomBytes)
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');

    // Format the UUID v7-like string
    const timePart1 = timeHex.slice(0, 8);
    const timePart2 = timeHex.slice(8, 12);
    const randPart1 = randHex.slice(0, 3);
    const randPart2 = randHex.slice(3, 6);
    const randPart3 = randHex.slice(6, 18);

    return `${timePart1}${timePart2}7${randPart1}8${randPart2}${randPart3}`.toUpperCase();
}

// Example usage
console.log(invUUIDv7());

const customParamsSerializer = (params: Record<string, any>): string => {
    const query: string[] = [];
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key];
            if (Array.isArray(value)) {
                value.forEach(item => {
                    query.push(`${key}=${item}`);
                });
            } else {
                query.push(`${key}=${value}`);
            }
        }
    }
    return query.join('&');
};
// const bucket = 'irdbMain';
const bucket = 'apresskin';
// const bucket = 'dove-dev';

const api = axios.create({
    // baseURL: 'https://ire-ephemeral-130-syih4bgfoq-uc.a.run.app',
    // baseURL: 'https://ire-beta-505492214494.us-central1.run.app',
    // baseURL: 'https://dev.backend.irdb.com',
    baseURL: 'https://ire-ephemeral-134-505492214494.us-central1.run.app/',
    // baseURL: 'http://127.0.0.1:1337/',
    // baseURL: 'https://production.backend.irdb.com',
    paramsSerializer: customParamsSerializer,
});
// curlirize(api);

export interface TVision {
    query(
        image: ImageOperation<any>,
        onProgress: (progress: ImageOperation<Query>) => void,
    ): CancellablePromise<ImageOperation<Query>>;
}

type ProgressCallback = (progress: number) => void;

async function postWithFetch(
    // bucket: string,
    arrayBuffer: ArrayBuffer,
    // arrayBuffer: string,
    // boxes: [number, number, number, number][],
    boxes: string[],
    onProgress?: ProgressCallback,
    abortController?: AbortController,
): Promise<string> {
    // console.log('postWithFetch');
    // Build the URL with query parameters
    const baseUrl = 'https://ire-ephemeral-134-505492214494.us-central1.run.app'; // Replace with your API base URL
    const url = new URL(`/v1/${bucket}`, baseUrl);
    url.searchParams.append('apikey', '42');
    url.searchParams.append('content', 'false');
    url.searchParams.append('live', 'true');
    // boxes.forEach(box => url.searchParams.append('region', `${box[0]},${box[1]},${box[2]},${box[3]}`));
    for (const box of boxes) {
        url.searchParams.append('region[]', box);
    }
    // url.searchParams.append('region', boxes);
    url.searchParams.append('status', 'OK');

    // Define headers
    const headers: HeadersInit = {
        'Content-Type': 'image/jpeg',
    };

    return new Promise<string>((resolve, reject) => {
        // Fetch options
        const fetchOptions: RequestInit = {
            method: 'POST',
            headers,
            body: arrayBuffer,
            signal: abortController?.signal,
            // @ts-ignore
            duplex: 'half', // Required for streaming requests
        };

        // Simulate upload progress
        const bodyStream = new ReadableStream<Uint8Array>({
            start(controller) {
                const chunkSize = 64 * 1024; // 64KB
                let uploadedBytes = 0;

                const uploadChunks = () => {
                    const chunk = arrayBuffer.slice(uploadedBytes, uploadedBytes + chunkSize);
                    if (chunk.byteLength === 0) {
                        controller.close();
                        return;
                    }

                    controller.enqueue(new Uint8Array(chunk));
                    uploadedBytes += chunk.byteLength;

                    // Call the upload progress callback
                    if (onProgress) {
                        onProgress(uploadedBytes / arrayBuffer.byteLength);
                    }

                    setTimeout(uploadChunks, 10); // Simulate delay
                };

                uploadChunks();
            },
        });

        // Make the fetch request
        fetch(url.toString(), { ...fetchOptions, body: bodyStream })
            .then(async response => {
                if (!response.body) {
                    reject(new Error('ReadableStream not supported or empty response.'));
                    return;
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let fullResponse = '';
                let receivedBytes = 0;

                while (true) {
                    const { done, value } = await reader.read();
                    // if (done) break;
                    if (done) {
                        console.log('Stream fully consumed.');
                        break;
                    }

                    // Convert the bytes to a string (optional, depends on your data)
                    const chunk = decoder.decode(value, { stream: true });
                    // receivedData += chunk;

                    console.log('Received chunk:', chunk);

                    if (value) {
                        receivedBytes += value.byteLength;
                        fullResponse += decoder.decode(value, { stream: true });

                        // Call the download progress callback
                        const contentLength = response.headers.get('Content-Length');
                        if (contentLength && onProgress) {
                            onProgress(receivedBytes / parseInt(contentLength, 10));
                        }
                    }
                }

                resolve(fullResponse);
            })
            .catch(reject);
    });
}

const useVision = (): TVision => {
    const { fetchImage } = useFetchImage();
    // const trackingId = useRef<string>(uuidv4().replace(/-/g, ''));
    const trackingId = useRef<string>(invUUIDv7());

    const querySemaphore = new Semaphore(10);
    const query = (
        imageOperation: ImageOperation<any>,
        onProgress: (progress: ImageOperation<Query>) => void,
    ): CancellablePromise<ImageOperation<Query>> => {
        const promise = new AbortablePromise<ImageOperation<Query>>(async (resolve, reject, abortController) => {
            // console.log('query', imageOperation.id);
            const _onProgress = getProgressHandler(onProgress, abortController);
            _onProgress({
                ...imageOperation,
                operation: {
                    type: Type.Query,
                    status: 'Waiting to query...',
                    Pending: true,
                    Completed: false,
                },
            });

            const [value, release] = await querySemaphore.acquire();
            const queryStart = performance.now();

            abortController.signal.addEventListener('abort', release);

            _onProgress({
                ...imageOperation,
                operation: {
                    type: Type.Query,
                    status: 'Querying...',
                    Pending: true,
                    Completed: false,
                },
            });

            // TODO: Failure cases need to be handled in one way

            try {
                const newImageOperation = await new AbortablePromise<ImageOperation<Query>>(
                    async (innerResolve, innerReject) => {
                        if (imageOperation.file === undefined) {
                            throw new Error('Image file is undefined');
                        }

                        imageOperation.debug = debug(imageOperation, 'processStartTime', performance.now());

                        // This returns a File, but maybe we just want the bytes?
                        // I really need a File | BLOB | string | ArrayBuffer flow chart
                        const { file, size } = await convertToJpeg(imageOperation.file as File, {
                            quality: 0.25,
                            maxDimension: 1280,
                        });
                        // console.log('jpg', jpg);
                        imageOperation.debug = debug(imageOperation, 'queryImage', URL.createObjectURL(file));
                        imageOperation.debug = debug(imageOperation, 'queryResolution', size);

                        const boxes = calculateFoveationRegionsWithImageSize(size);
                        // console.log('boxes', JSON.stringify(boxes));

                        imageOperation.debug = debug(imageOperation, 'processEndTime', performance.now());

                        try {
                            imageOperation.debug = debug(imageOperation, 'queryStartTime', performance.now());
                            const response = await api.post(`/v1/${bucket}`, file, {
                                headers: {
                                    'Content-Type': 'image/jpeg',
                                    // 'Tracking-Id': trackingId.current,
                                },
                                params: {
                                    apikey: 42,
                                    content: false,
                                    live: true,
                                    // region=0,280,720,720&region=180,460,360,360
                                    // region: ['0,280,720,720', '180,460,360,360'],
                                    // region: boxes.map(box => `${box[0]},${box[1]},${box[2]},${box[3]}`),
                                    // Return 200 OK instead of 404 on missing IRCODE
                                    status: 'OK',
                                },
                                onUploadProgress: progressEvent => {
                                    // console.log('progressEvent.progress', progressEvent.progress);
                                    // onProgress?.(progressEvent.progress ?? 0);
                                },
                                onDownloadProgress: progressEvent => {
                                    // console.log('progressEvent.progress', progressEvent.progress);
                                    // onProgress?.(progressEvent.progress ?? 0);
                                },
                                signal: abortController?.signal,
                                // ...axiosParams,
                            });
                            imageOperation.debug = debug(imageOperation, 'queryEndTime', performance.now());
                            // console.log('response', response);

                            // const response2 = await postWithFetch(
                            //     // bucket,
                            //     arrayBuffer,
                            //     boxes.map(box => `${box[0]},${box[1]},${box[2]},${box[3]}`),
                            //     progress => {
                            //         // console.log('progress', progress);
                            //         // onProgress?.(progress);
                            //     },
                            //     abortController,
                            // );
                            // console.log('response2', response2);

                            if (response.data.match === undefined) {
                                //throw new Error('No entry in response');
                                // console.warn('No entry in response');
                                // return innerReject('No entry');
                                innerResolve({
                                    ...imageOperation,
                                    operation: {
                                        type: Type.Query,
                                        Pending: false,
                                        // TODO: Need a correct value... and also this is currently "string"
                                        status: RegistrationStatus.Available,
                                        Completed: true,
                                        // Results: {
                                        //     ImageAlreadyExists: true,
                                        //     Image: ircode,
                                        // },
                                        // ...response?.data,
                                    },
                                    status: RegistrationStatus.Available,
                                });
                                return;
                            } else {
                                console.log('response.data.match', response.data.match);
                            }

                            // TODO: Type the response from IREngine
                            // {
                            //     "image": {
                            //         "url": "http://ire-ephemeral-130-syih4bgfoq-uc.a.run.app/v1/irdbMain/8B1F1D8612094E3AAAB3C5DEFA9CFA6F/image/0"
                            //     },
                            //     "similarity": 0.818068116903305,
                            //     "entry": "8B1F1D8612094E3AAAB3C5DEFA9CFA6F"
                            // }

                            const ircode = await fetchImage(response.data.entry);
                            // console.log('ircode', ircode);
                            // imageOperation.operation.Results.image = ircode;

                            innerResolve({
                                ...imageOperation,
                                operation: {
                                    type: Type.Query,
                                    Pending: false,
                                    // TODO: Need a correct value... and also this is currently "string"
                                    status: RegistrationStatus.Unavailable,
                                    Completed: true,
                                    Results: {
                                        ImageAlreadyExists: true,
                                        Image: ircode,
                                    },
                                    // ...response?.data,
                                },
                                status: RegistrationStatus.Unavailable,
                            });
                        } catch (error) {
                            console.warn(error);
                            innerReject(error);
                        }
                    },
                    abortController,
                );

                // console.log('query out', newImageOperation.id);

                if (newImageOperation) {
                    resolve(newImageOperation);
                } else {
                    throw new Error('File read error');
                }
            } catch (error: any) {
                console.warn(error);
                if (error instanceof CanceledError) {
                    reject(new AbortError());
                    return;
                }
                resolve({
                    ...imageOperation,
                    operation: {
                        type: Type.Query,
                        Pending: false,
                        ...error.response?.data,
                    },
                });
            } finally {
                release();
                abortController.signal.removeEventListener('abort', release);
            }
        });

        return {
            id: imageOperation.id,
            promise,
            cancel: () => promise.abort(),
        };
    };

    return {
        query,
    };
};

export default useVision;
