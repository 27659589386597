import { create } from 'zustand';

const useDocumentVisibilityStore = create<DocumentVisibilityState>()(() => document.visibilityState);

window.addEventListener('visibilitychange', () => {
    useDocumentVisibilityStore.setState(document.visibilityState);
});

const useDocumentVisibilityState = () => {
    return useDocumentVisibilityStore();
};

export default useDocumentVisibilityState;
