import { useContext, useEffect, useRef } from 'react';
import { MetaContent, MetaType } from '../types/MetaTypes';
import MediaContext, {
    ImageOperation,
    imageFromImageOperation,
    TMedia,
    Debug,
    Init,
    Query,
} from 'src/contexts/MediaContext';
import useBulkUploaderState from 'src/stores/useBulkUploaderState';
import { dataUrlToFile, Size } from 'src/util/image';
import useExpiringState from './useExpiringState';
import useVision from './useVision';
import { Image } from '../types/Image';
import useDocumentVisibilityState from './util/useDocumentVisibilityState';
import useWindowHasFocus from './util/useWindowHasFocus';

interface Base64ImageData {
    data: string;
    size: Size;
}

interface WhatAndWhen<T> {
    what: T;
    when: number;
}

class FixedSizeArray<T> {
    private array: T[] = [];
    private maxSize: number;

    constructor(maxSize: number) {
        this.maxSize = maxSize;
    }

    add(item: T): void {
        if (this.array.length >= this.maxSize) {
            this.array.shift(); // Remove the oldest item
        }
        this.array.push(item);
    }

    getItems(): T[] {
        return this.array;
    }

    popLastItem(): T | undefined {
        return this.array.pop();
    }

    clear() {
        this.array = [];
    }
}

export interface TLiveVisualSearch {
    addtoQueue: (base64: string, size: Size, timestamp: number) => void;
    currentLiveVisualSearchResult: ImageOperation<any> | undefined;
    currentLiveVisualSearchResultProducts: MetaContent<MetaType.ProductLink> | undefined;
}

const useLiveVisualSearch = (
    setDebug: (debug?: Debug) => void,
    setImageOperationWithScanToSite: (imageOperation: ImageOperation<any>) => void,
): TLiveVisualSearch => {
    const { init, prep, foveate, upload, query, quick, add, load } = useContext(MediaContext) as TMedia;
    const { query: visionQuery } = useVision();

    const [currentLiveVisualSearchResult, setCurrentLiveVisualSearchResult] = useExpiringState<ImageOperation<any>>(
        2500,
        undefined,
    );
    const [currentLiveVisualSearchResultProducts, setCurrentLiveVisualSearchResultProducts] = useExpiringState<
        MetaContent<MetaType.ProductLink>
    >(5000, undefined);
    // const [currentLiveVisualSearchResult, setCurrentLiveVisualSearchResult] = useExpiringState<ImageOperation<any>>(
    //     2500,
    //     undefined,
    // );

    const storeApi = useBulkUploaderState();
    // console.log('storeApi', storeApi);
    const useSelectors = storeApi.use;
    const processFiles = useSelectors.processFiles();

    useEffect(
        () =>
            storeApi.subscribe((state, prevState) => {
                const hasImageOperations = state.imageOperationsCount > 0;
                const hadImageOperations = prevState.imageOperationsCount > 0;
                if (hasImageOperations && !hadImageOperations) {
                    // console.log('on Start');
                    // onStart();
                } else if (!hasImageOperations && hadImageOperations) {
                    // console.log('on Complete');
                    // onComplete();
                }
            }),
        [storeApi /*onStart, onComplete */],
    );

    useEffect(
        () =>
            storeApi.subscribe((state, prevState) => {
                const { files } = state;
                const { files: prevFiles } = prevState;
                if (files.length === 0 || files === prevFiles) {
                    return;
                }
                void processFiles();
            }),
        [storeApi, processFiles],
    );

    // const onStream = useBulkUploaderState().use.onDrop();
    // TODO: Use this to determine if there's a match
    const useBulkUploaderSelectors = useBulkUploaderState().use;
    const availableIrcodes = useBulkUploaderSelectors.availableIrcodes();
    const unavailableIrcodes = useBulkUploaderSelectors.unavailableIrcodes();
    // const howmany = useBulkUploaderSelectors.imageOperationsCount();

    // const useBulkUploaderSelectors = useBulkUploaderState().use;
    // const imageOperations = useBulkUploaderSelectors.availableIrcodes();

    const removeImageOperation = useBulkUploaderSelectors.removeImageOperation();
    const replaceImageOperation = useBulkUploaderSelectors.replaceImageOperation();

    // useEffect(() => {
    //     // console.log('howmany', howmany);
    // }, [howmany]);

    useEffect(() => {
        // console.log('availableIrcodes', availableIrcodes.length);

        availableIrcodes.forEach(availableIrcode => {
            removeImageOperation(availableIrcode);
        });
    }, [availableIrcodes, removeImageOperation]);

    useEffect(() => {
        // console.log('unavailableIrcodes', unavailableIrcodes.length);
        const unavailableIrcode = structuredClone(unavailableIrcodes[unavailableIrcodes.length - 1]);
        // console.log('use', use);
        if (unavailableIrcode) {
            // console.log('\n\n\n', 'title', use.operation.Results?.Image?.metaContent?.title, '\n\n\n');
            // console.log('unavailableIrcode', JSON.stringify(unavailableIrcode, null, 4));
            const image = imageFromImageOperation(unavailableIrcode) as Image;
            console.log('unavailableIrcode', unavailableIrcode);
            console.log('image', image);

            // image can be undefined, don't love that...
            if (!image) {
                return;
            }

            // console.log(
            //     'metaContentForMetaType(image, MetaType.ProductLink).links',
            //     metaContentForMetaType(image, MetaType.ProductLink).links
            // );
            switch (true) {
                // case metaContentForMetaType(image, MetaType.ProductLink)?.links.length > 0:
                //     setCurrentLiveVisualSearchResult(undefined);
                //     setCurrentLiveVisualSearchResultProducts(metaContentForMetaType(image, MetaType.ProductLink));
                //     // console.log('IRCODE');
                //     break;
                // case metaContentForMetaType(image, MetaType.):
                //     // console.log('IRCODES');
                //     break;
                default:
                    setCurrentLiveVisualSearchResult(unavailableIrcode);
                    // setCurrentLiveVisualSearchResultProducts(undefined);
                    // setImageOperationWithScanToSite(unavailableIrcode);
                    break;
            }
            // setImageOperationWithScanToSite
            // unavailableIrcode.operation.Results. = false;
            // currentLiveVisualSearchResult.operation.Results?.Image?.

            // TODO: is it a badge?
            // TODO: Are there products?
            // TODO: Is it an IRCODE
        }

        unavailableIrcodes.forEach(unavailableIrcode => {
            removeImageOperation(unavailableIrcode);
        });
    }, [removeImageOperation, setCurrentLiveVisualSearchResult, unavailableIrcodes]);

    const imageQueueRef = useRef<FixedSizeArray<WhatAndWhen<Base64ImageData>>>(
        new FixedSizeArray<WhatAndWhen<Base64ImageData>>(2),
    );
    const lastAddedTime = useRef<number>(Date.now());
    const queueIntervalId = useRef<number>();
    const isWindowHidden = useDocumentVisibilityState() === 'hidden';
    const windowHasFocus = useWindowHasFocus();

    useEffect(() => {
        if (queueIntervalId.current) {
            clearInterval(queueIntervalId.current);
            queueIntervalId.current = undefined;
        }
        if (isWindowHidden || !windowHasFocus) {
            imageQueueRef.current.clear();
            return;
        }
        queueIntervalId.current = window.setInterval(() => {
            const whatAndWhen = imageQueueRef.current.popLastItem();
            // const base64 = whatAndWhen?.what;

            if (!whatAndWhen) {
                // Hasn't initialized or is paused
                return;
            }

            // TODO: base64 comes off the webcam and i have delayed converting to File until now. i want to avoid a larger rewrite right now
            const file = dataUrlToFile(whatAndWhen.what.data);

            if (file) {
                // addtoQueue(file);

                const i = init(file, whatAndWhen.what.size, whatAndWhen.when, (progress: ImageOperation<Init>) => {
                    replaceImageOperation(progress);
                });

                // updateCancelHandlers(i);
                // const initialized = await i.promise;
                i.promise.then(async initialized => {
                    const p = prep(initialized[0], true);
                    // updateCancelHandlers(p);
                    const prepped = await p.promise;
                    replaceImageOperation(prepped);

                    // Test out speeds on these both...

                    // IREngine and BE...
                    const v = visionQuery(prepped, (progress: ImageOperation<Query>) => {
                        replaceImageOperation(progress);
                    });
                    const queried = await v.promise;
                    // ...IREngine and BE

                    // Just BE...
                    // const q = quick(prepped, (progress: ImageOperation<Query>) => {
                    //     replaceImageOperation(progress);
                    // });
                    // const queried = await q.promise;
                    // ...Just BE

                    setDebug(queried.debug);

                    replaceImageOperation(queried);
                });
            }
        }, 100);

        return () => {
            if (queueIntervalId.current) {
                clearInterval(queueIntervalId.current);
                queueIntervalId.current = undefined;
            }
        };
    }, [isWindowHidden, windowHasFocus]);

    const addtoQueue = async (base64: string, size: Size, timestamp: number) => {
        // TODO: This should just debounce
        if (lastAddedTime.current > Date.now() - 1000) {
            // console.log('Too fast');
            return;
        }

        lastAddedTime.current = Date.now();
        imageQueueRef.current.add({
            what: {
                data: base64,
                size,
            },
            when: timestamp,
        });
    };

    return {
        addtoQueue,
        currentLiveVisualSearchResult,
        currentLiveVisualSearchResultProducts,
    };
};

export default useLiveVisualSearch;
