import { Box, Button, Stack, Typography } from '@mui/material';
import { useRef, useState, useEffect, useContext, useImperativeHandle, MutableRefObject } from 'react';
import Webcam from 'react-webcam';
import { getSelectorsByUserAgent } from 'react-device-detect';
import usePinch from 'src/hooks/usePinch';
import useCameraZoom from 'src/hooks/useCameraZoom';
import { CAMERA_CAPTURE_FILENAME, convertToJpeg, dataUrlToFile, Size } from 'src/util/image';
import useVideoStream from 'src/hooks/useWebcamStream';
import useCameraPermissions from 'src/hooks/useCameraPermissions';
import isMobileJs from 'ismobilejs';
import { useThrottle } from '@uidotdev/usehooks';
import EnvContext, { TEnv } from 'src/contexts/EnvContext';

export interface LiveViewRef {
    capture: () => void;
}

interface LiveViewProps {
    liveViewRef?: MutableRefObject<LiveViewRef | undefined>;
    pause: boolean;
    pauseStream: boolean;
    onPhoto: (file: File, timestamp: number) => void;
    onStream: (base64: string, size: Size, timestamp: number) => void;
    theme: any;
}

const getUserMediaParams: MediaStreamConstraints = {
    video: {
        facingMode: 'environment',
        advanced: [
            {
                // width: { min: 640, ideal: 1920, max: 1920 },
                // height: { min: 480, ideal: 1080, max: 1080 },
                width: { min: 1920 / 2, ideal: 1920 * 2, max: 1920 * 4 },
                height: { min: 1080 / 2, ideal: 1080 * 2, max: 1080 * 4 },
            },
        ],
    },
};

// Snowflakes for apresskin
const generateSnowflakes = () => {
    const snowflakes = [];
    for (let i = 0; i < 16; i++) {
        const size = Math.random() * 1.5 + 1.2;
        const opacity = Math.random() * 0.4 + 0.3;
        const left = Math.random() * 100;
        const duration = Math.random() * 13 + 8;
        const delay = Math.random() * 16 + -8;
        snowflakes.push(
            <div
                key={i}
                style={{
                    position: 'absolute',
                    top: '-2vw',
                    left: `${left}vw`,
                    width: `${size}vw`,
                    height: `${size}vw`,
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    opacity: opacity,
                    animation: `fall ${duration}s linear ${delay}s infinite`,
                }}
            />,
        );
    }
    return snowflakes;
};
const snowflakes = generateSnowflakes();

const LiveView = ({ liveViewRef, pause, pauseStream, onPhoto, onStream, theme }: LiveViewProps) => {
    const { isIOS } = getSelectorsByUserAgent(window.navigator.userAgent);

    const captureSizeRef = useRef<{ width: number; height: number }>();
    const [screenshot, setScreenshot] = useState<string | null>(null);
    const { hasPermission, setHasPermission } = useCameraPermissions();
    const { zoomLevel, setZoom, isZoomSupported } = useCameraZoom();
    const [pinchAmount, setPinchAmount] = useState(0);
    const throttledPinchAmount = useThrottle(pinchAmount, 250);
    const { pinchProps } = usePinch({
        enabled: isZoomSupported,
        onPinch(amount) {
            if (isIOS) {
                // throttle iOS pinching for lag
                setPinchAmount(amount);
            } else {
                setZoom(zoomLevel.current + amount / 100);
            }
        },
    });

    const liveVisualSearchInterval = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        const liveVisualSearchEnabled = true;
        if (liveVisualSearchEnabled) {
            liveVisualSearchInterval.current = setInterval(async () => {
                if (webcamRef.current) {
                    // Returns a base64 encoded string
                    // const dataUrl = webcamRef.current.getScreenshot({ width: 720, height: 1280 });
                    const dataUrl = webcamRef.current.getScreenshot(captureSizeRef.current);

                    // TODO: why convert them all when we may only send a few?

                    // console.log('dataUrl', dataUrl);

                    if (dataUrl) {
                        if (!captureSizeRef.current) {
                            const file = dataUrlToFile(dataUrl, 'capture.jpg');
                            const { size } = await convertToJpeg(file, { maxDimension: 1280 });
                            console.log('Using capture size', size);
                            captureSizeRef.current = size;
                        }

                        // shrink(jpg, 1280, 1280);
                        // convertToJpeg;

                        // if (!captureSizeRef.current) {
                        //     const image = await loadImage(dataUrl);
                        //     captureSizeRef.current = {
                        //         width: image.width,
                        //         height: image.height,
                        //     };
                        // }

                        // onStream(dataUrlToFile(imageSrc));
                        onStream(dataUrl, captureSizeRef.current!, performance.now());
                    }
                } else {
                    // console.log('no webcamRef');
                }
            }, 100);
        }

        return () => {
            if (liveVisualSearchInterval.current) {
                clearInterval(liveVisualSearchInterval.current);
            }
        };
    }, []);

    const { client } = useContext(EnvContext) as TEnv;

    useEffect(() => {
        setZoom(zoomLevel.current + throttledPinchAmount / 20);
    }, [throttledPinchAmount]);

    const handleTouchMove = (event: TouchEvent) => {
        event.preventDefault();
    };

    useEffect(() => {
        const touchMoveOptions = {
            passive: false,
        } as unknown as EventListenerOptions;

        if (isIOS && !pause) {
            window.addEventListener('touchmove', handleTouchMove, touchMoveOptions);
        } else if (isIOS) {
            window.removeEventListener('touchmove', handleTouchMove, touchMoveOptions);
        }

        return () => {
            if (isIOS) {
                window.removeEventListener('touchmove', handleTouchMove, touchMoveOptions);
            }
        };
    }, [isIOS, pause]);

    const { stream, setStream } = useVideoStream();

    useEffect(() => {
        if (pause) {
            setStream(null);
        }
    }, [pause]);

    const requestCameraPermission = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia(getUserMediaParams);
            setHasPermission(true);
            mediaStream.getTracks().forEach(track => track.stop());
        } catch (err) {
            setHasPermission(false);
        }
    };

    useEffect(() => {
        const { apple } = isMobileJs();
        if (!stream && (apple.phone || apple.tablet || apple.ipod) && hasPermission) {
            requestCameraPermission();
        }
    }, [stream, hasPermission]);

    const webcamRef = useRef<Webcam>();

    useImperativeHandle(
        liveViewRef,
        () => ({
            capture: () => {
                if (webcamRef.current) {
                    webcamRef.current.video?.pause();

                    // TODO: Choose one here...,
                    // const imageSrc = webcamRef.current.getScreenshot({ width: 1080 * 2, height: 1920 * 2 });
                    const imageSrc = webcamRef.current.getScreenshot();
                    // console.log('imageSrc', imageSrc);

                    if (imageSrc) {
                        setScreenshot(imageSrc);
                        onPhoto(dataUrlToFile(imageSrc, CAMERA_CAPTURE_FILENAME), performance.now());
                    }
                }
            },
        }),
        [onPhoto],
    );

    const onUserMedia = async (stream: MediaStream) => {
        // console.log('stream', stream);
        // console.log('stream.getVideoTracks()', stream.getVideoTracks());
        setStream(stream);
    };

    // TODO: Match orientation
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
            }}
        >
            {hasPermission === false &&
                (client === 'brandweek' ?
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#5B59FB',
                            color: 'white',
                            zIndex: 2,
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={4}
                            alignItems="center"
                            sx={{ maxWidth: '90%', justifyContent: 'center', height: 'calc(100% - 130px)' }}
                        >
                            <img alt="Logo" src="/images/clients/brandweek_logo.svg" />
                            <Typography variant="metaDataValue" align="center">
                                Use your camera at the event to scan badges, <br />
                                get info, and add people to your contacts.
                            </Typography>
                            <Button
                                variant="irdbGradient"
                                sx={{
                                    background: 'rgba(255, 255, 255, 0.2)',
                                    py: 1.5,
                                    px: 4,
                                }}
                                onClick={requestCameraPermission}
                            >
                                Enable Camera Access
                            </Button>
                            <img alt="Powered by IRCODE" src="/images/clients/brandweek_ircode.svg" />
                        </Stack>
                    </Box>
                : client === 'apresskin' ?
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            width: '100%',
                            height: '100%',
                            background:
                                'url("/images/clients/apres_border_top.png") 0 0/contain no-repeat, url("/images/clients/apres_border_bottom.png") 100% 100%/contain no-repeat, url("/images/clients/apres_flake1.svg") 99% 80%/8vh no-repeat #811425',
                            color: 'white',
                            zIndex: 2,
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <style>
                            {`@keyframes fall {
                                0% { transform: translateY(0) translateX(0); }
                                100% { transform: translateY(100vh) translateX(30vw); }
                            }`}
                        </style>
                        {snowflakes}
                        <Stack
                            direction="column"
                            spacing={4}
                            alignItems="center"
                            sx={{ maxWidth: '90%', justifyContent: 'center', height: '100%' }}
                        >
                            <img alt="Logo" src="/images/clients/apres_logo.svg" />
                            <Typography
                                variant="metaDataValue"
                                align="center"
                                sx={{
                                    maxWidth: 310,
                                    fontWeight: 400,
                                }}
                            >
                                Use your camera to scan products and signs throughout the event to shop the limited
                                edition Dove Holiday Treats collection at Walmart and Target.
                            </Typography>
                            <Button
                                variant="irdbGradient"
                                sx={{
                                    background: 'rgba(255, 255, 255, 0.8)',
                                    color: '#811425',
                                    py: 1.5,
                                    px: 0,
                                    width: 300,
                                    textTransform: 'uppercase',
                                    position: 'relative',
                                    '&:hover': {
                                        background: 'white',
                                    },
                                }}
                                onClick={requestCameraPermission}
                            >
                                Enable Camera Access
                                <img
                                    src="/images/clients/apres_flake2.svg"
                                    style={{
                                        fontSize: '1vh',
                                        position: 'absolute',
                                        left: '1em',
                                        bottom: '-11em',
                                        width: '12em',
                                        transform: 'rotate(-6deg)',
                                    }}
                                />
                            </Button>
                            <img
                                style={{
                                    position: 'absolute',
                                    bottom: '3em',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                }}
                                alt="Powered by IRCODE"
                                src="/images/clients/apres_ircode.svg"
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '0',
                                    right: '0',
                                    margin: '0',
                                    zIndex: 2,
                                    pointerEvents: 'none',
                                    fontSize: '1vh',
                                }}
                            >
                                <img
                                    src="/images/clients/apres_flake3.svg"
                                    style={{
                                        position: 'absolute',
                                        top: '8em',
                                        right: '7em',
                                        width: '10em',
                                        transform: 'rotate(-23deg)',
                                    }}
                                />
                            </div>
                        </Stack>
                    </Box>
                :   <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.9)',
                            color: 'white',
                            zIndex: 2,
                        }}
                    >
                        <Stack direction="column" spacing={4} alignItems="center" sx={{ maxWidth: '90%' }}>
                            <img
                                id="PrePermissionLogo"
                                alt="Logo"
                                style={{
                                    objectFit: 'contain',
                                    marginBottom: '1rem',
                                }}
                            />
                            <Typography variant="headlineMedium" align="center">
                                Enable Camera Access
                            </Typography>
                            <Typography variant="metaDataValue" align="center">
                                To get the most out of the app, you'll need to allow camera access for IRCODE to begin
                                scanning and searching images
                            </Typography>
                            <Button variant="irdbGradient" onClick={requestCameraPermission}>
                                Enable Camera
                            </Button>
                        </Stack>
                    </Box>)}
            {hasPermission && (
                <Box
                    {...pinchProps}
                    sx={{
                        // Disables default pinch-zoom behavior
                        touchAction: 'pan-x pan-y',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        bottom: '0',
                        right: '0',
                        width: '100%',
                        zIndex: '1',
                    }}
                >
                    {!pause && (
                        <>
                            <Webcam
                                // TODO: More refs not working with typescript
                                // @ts-ignore
                                ref={webcamRef}
                                videoConstraints={{
                                    zoom: true,
                                    // @ts-ignore
                                    ...getUserMediaParams.video,
                                }}
                                width="100%"
                                height="100%"
                                //
                                // Testing...
                                imageSmoothing={false}
                                forceScreenshotSourceSize={true}
                                screenshotQuality={1}
                                // ...Testing
                                //
                                screenshotFormat="image/jpeg"
                                onUserMedia={onUserMedia}
                                // mirrored={true}
                                style={{
                                    opacity: pause ? 0 : 1,
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    zIndex: '1',

                                    // transform: "scale(" + 1/minZoomLevel +")" //<- obtain that zoomlevel from some video / webcam component. if it goes below 1, then there's a wide-angle lens, and we can use that and adjust the view accordingly. if not, then this is scale(1) and we're good.
                                }}
                                poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            />
                            <Box
                                sx={{
                                    width: '60vw',
                                    height: '62vw',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    filter: 'drop-shadow(0 0 .2em black)',
                                    zIndex: 2,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                }}
                            >
                                <svg
                                    viewBox="-1 -1 37 38"
                                    style={{
                                        opacity: 0.5,
                                        width: '100%',
                                    }}
                                >
                                    <path
                                        d="M0 0 9 0M26 0 35 0 35 9M35 27 35 36 26 36M9 36 0 36 0 27M0 9 0 0 2 0"
                                        stroke="#fff"
                                        strokeWidth=".33"
                                        fill="none"
                                    />
                                </svg>
                            </Box>
                        </>
                    )}
                    {pause && screenshot && (
                        <img
                            width="100%"
                            height="100%"
                            src={screenshot}
                            style={{
                                objectFit: 'cover',
                                position: 'absolute',
                                zIndex: '2',
                                top: '0',
                                left: '0',
                            }}
                            alt="Processing..."
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default LiveView;
