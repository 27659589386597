import { create } from 'zustand';

const useWindowHasFocusStore = create<boolean>(() => document.hasFocus());

window.addEventListener('focus', () => {
    useWindowHasFocusStore.setState(true);
});

window.addEventListener('blur', () => {
    useWindowHasFocusStore.setState(false);
});

const useWindowHasFocus = () => {
    return useWindowHasFocusStore();
};

export default useWindowHasFocus;
